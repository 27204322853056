"use strict";
//from ws
//const PRODUCTS = "10";
Object.defineProperty(exports, "__esModule", { value: true });
const QUOTE = "20";
const ORDERS = "30";
const ACCOUNT_STATES = "40";
const INVOICES = "50";
const SUPPORT = "60";
const MY_ORDERS = "70"; //parent
const REQUIREMENT = "80"; //parent
const PLANNING = "90";
const MY_ORDERS__OPEN_ORDERS = "701"; //child
const MY_ORDERS__ORDERS_HISTORY = "702"; //child
const UPLOAD_EXCEL = "100";
const BTU_GESTION = "103";
const BTU_SALE = "101";
const BTU_HISTORY = "102";
const BTU_DISCOUNT = "104";
const BTU_PRICES = "105";
//add
const MENU = "MENU";
const USER = "USER";
const SIGN_OUT = "SIGN_OUT";
exports.default = {
    PLANNING,
    //PRODUCTS,
    QUOTE,
    ORDERS,
    ACCOUNT_STATES,
    INVOICES,
    SUPPORT,
    MY_ORDERS,
    MY_ORDERS__OPEN_ORDERS,
    MY_ORDERS__ORDERS_HISTORY,
    REQUIREMENT,
    UPLOAD_EXCEL,
    BTU_GESTION,
    BTU_SALE,
    BTU_HISTORY,
    BTU_DISCOUNT,
    BTU_PRICES,
    SIGN_OUT,
    MENU,
    USER,
};
