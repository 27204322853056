import doLogin from "./login";
import doConfig from "./config";
import doClienteDirecciones from "./cliente_direcciones";
import {doCatalogo, doCatalogItemInfo} from "./catalogo";
import doPerfil from "./perfil";
import {doAlmacenes, doAlmacenesRV} from "./almacenes";
import doClientes from "./clientes";
import doPedidoHistorial from "./pedido_historial";
import {doAccountState, doAccountStateDownload} from "./account_state";
export * from "./order";
export * from "./version";
export * from "./merch";
export * from "./promotion";
export * from "./firebase";
export * from "./tactico_meta";
import {
	doCarritoAdd,
	doCarritoRemove,
	doCarritoClear,
	doCarritoList,
	doCarritoConfirm,
	doCarritoAffect,
	doCarritoPrice,
	doCarritoDiscounts,
	doCarritoMerch,
	doCarritoCotiAdd,
	doCarritoCotiRemove,
	doCarritoCotiList,
	doCarritoCotiPrice,
	doCarritoCotiDiscounts,
} from "./carrito";
import {doStockAlert} from "./stock_alert";

export * from "./quote";
export * from "./invoice";
export * from "./cpe_download";
export * from "./support";
export * from "./roadmap";
export * from "./stock_alert";

const Services = {
	doLogin,
	doConfig,
	doClienteDirecciones,
	doCatalogo,
	doPerfil,
	doAlmacenes,
	doAlmacenesRV,
	doClientes,
	doPedidoHistorial,
	doCarritoAdd,
	doCarritoRemove,
	doCarritoClear,
	doCarritoList,
	doCarritoAffect,
	doCarritoPrice,
	doCarritoDiscounts,
	doCarritoMerch,
	doCarritoConfirm,
	doCarritoCotiAdd,
	doCarritoCotiRemove,
	doCarritoCotiList,
	doAccountState,
	doAccountStateDownload,
	doCatalogItemInfo,
	doStockAlert,
};
export default Services;
export {
	doLogin,
	doConfig,
	doClienteDirecciones,
	doCatalogo,
	doPerfil,
	doAlmacenes,
	doAlmacenesRV,
	doClientes,
	doPedidoHistorial,
	doCarritoAdd,
	doCarritoRemove,
	doCarritoClear,
	doCarritoList,
	doCarritoAffect,
	doCarritoPrice,
	doCarritoDiscounts,
	doCarritoMerch,
	doCarritoConfirm,
	doCarritoCotiAdd,
	doCarritoCotiRemove,
	doCarritoCotiList,
	doCarritoCotiPrice,
	doCarritoCotiDiscounts,
	doAccountState,
	doAccountStateDownload,
	doCatalogItemInfo,
	doStockAlert,
};
